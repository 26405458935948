<template>
  <validation-provider
    :rules="rulesObject"
    :custom-messages="messagesObject" 
    v-slot="validation"
    slim
  >
    <div :class="{...cssClasses, ...validationClasses(validation)}">
      <fr-label 
        for-id="fr-last-name"
        :class="validationClasses(validation)"
      >
        {{ field.meta.label }}
      </fr-label>
      <fr-input
        id="fr-last-name"
        :class="validationClasses(validation)"
        name="fr-last-name"
        type="text"
        :placeholder="field.meta.placeholder"
        autocomplete="off"
        v-model="fieldValue"
      ></fr-input>
      <fr-field-error v-if="validation.errors.length > 0">{{ validation.errors[0] }}</fr-field-error>
    </div>
  </validation-provider>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrInput from '@/components/fields/base/FrInput.vue'
import FrFieldError from '@/components/fields/base/FrFieldError.vue'
import validationHelper from '@/helpers/validation'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FrLastName',
  
  components: {
    FrInput,
    FrLabel,
    FrFieldError,
    ValidationProvider
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-last-name': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {String} val
     * 
     * @returns {String}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },

    /**
     * Creates a rules object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    rulesObject() {
      return validationHelper.createRulesObject(this.field.meta.rules)
    },

    /**
     * Creates a messages object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    messagesObject() {
      return validationHelper.createMessagesObject(this.field.meta.rules)
    }
  },

  methods: {
    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(validation) {
      return validationHelper.createValidationClasses(validation)
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>