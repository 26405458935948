<template>
  <div class="fr-form-controls">
    <fr-button 
      v-if="(formStepsCount > 1) && (currentFormStep > 1)" 
      :classes="['fr-button--previous']"
      @click.prevent="handlePreviousButton"
    >
      <span>{{ formControls.previous.text }}</span>
    </fr-button>
    
    <fr-button 
      v-if="(formStepsCount > 1) && (currentFormStep < formStepsCount)" 
      :classes="['fr-button--next']"
      @click.prevent="handleNextButton"
    >
      <span>{{ formControls.next.text }}</span>
    </fr-button>

    <fr-button 
      v-if="formStepsCount === currentFormStep"
      :classes="['fr-button--submit']"
      @click="handleSubmitButton"
    >
      <span>{{ formControls.submit.text }}</span>
    </fr-button>
  </div>
</template>

<script>
import FrButton from '@/components/fields/base/FrButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FrFormControls',
  
  components: {
    FrButton
  },

  props: {
    /**
     * The validation object which contains information about the field's current state.
     * 
     * Note that this is the ValidationObserver and not the ValidationProvider.
     */
    validation: {
      type: Object,
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formConfig: 'getFormConfig',
      currentFormStep: 'getCurrentFormStep',
      formStepsCount: 'getFormStepsCount'
    }),

    /** 
     * The form controls section of the form config.
     * 
     * @returns {Object}
     */
    formControls() {
      return this.formConfig.controls
    }
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions([
      'decrementFormStep',
      'incrementFormStep',
      'submitForm'
    ]),

    /**
     * Handles what happens when the submit button is clicked.
     * 
     * @returns {Void}
     */
    handleSubmitButton() {
      this.validation.handleSubmit(this.submitForm)
    },

    /**
     * Handles what happens when the next button is clicked.
     * 
     * @returns {Void}
     */
    handleNextButton() {
      this.validation.handleSubmit(this.incrementFormStep)
    },

    /**
     * Handles what happens when the previous button is clicked.
     * 
     * @returns {Void}
     */
    handlePreviousButton() {
      this.decrementFormStep()
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-form-controls {
  display: flex;
  justify-content: space-between;
}
</style>