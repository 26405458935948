<template>
  <validation-provider
    v-if="stateOptions.length > 1"
    :rules="rulesObject"
    :custom-messages="messagesObject" 
    v-slot="validation"
    slim
  >
    <div :class="{...cssClasses, ...validationClasses(validation)}">
      <fr-label 
        for-id="fr-state"
        :class="validationClasses(validation)"
      >
        {{ field.meta.label }}
      </fr-label>
      <fr-select
        id="fr-state"
        :class="validationClasses(validation)"
        name="fr-state"
        v-model="fieldValue"
        :options="stateOptions"
      ></fr-select>
      <fr-field-error v-if="validation.errors.length > 0">{{ validation.errors[0] }}</fr-field-error>
    </div>
  </validation-provider>
</template>

<script>
import defaultStates from '@/data/defaults/states.js'
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import FrFieldError from '@/components/fields/base/FrFieldError.vue'
import validationHelper from '@/helpers/validation'
import { ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FrState',

  components: {
    FrLabel,
    FrSelect,
    FrFieldError,
    ValidationProvider
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      selectedCountry: 'getSelectedCountry'
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-state': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },

    /**
     * Creates a rules object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    rulesObject() {
      return validationHelper.createRulesObject(this.field.meta.rules)
    },

    /**
     * Creates a messages object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    messagesObject() {
      return validationHelper.createMessagesObject(this.field.meta.rules)
    },

    /**
     * The default options to be rendered by the programs filter.
     * 
     * @returns {Array}
     */
    defaultOptions() {
      return [{
        label: this.field.meta.placeholder ?? 'Please select a State',
        value: '',
        selected: true,
        disabled: true
      }]
    },

    /**
     * The states to display on the form.
     * 
     * States may be overridden to have values either replaced, or appended.
     * 
     * @returns {Array}
     */
    stateOptions() {
      let states = []

      // We want to set the default US states if we're selecting 'United States of America' and we're not replacing the list.
      if (this.selectedCountry === 'United States of America' && this.field.meta.optionsMode !== 'replace') {
        states.push(...defaultStates)
      }

      if (this.field.meta.options.length > 0) {
        states.push(...this.field.meta.options)
      }

      states = this.sanitizeStateOptions(states)

      let filteredStates = states.filter(state => state.condition === this.selectedCountry || ! state.condition)

      return [
        ...this.defaultOptions,
        ...filteredStates.sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by label.
      ]
    }
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      updateSubmitObject: 'updateSubmitObject'
    }),

    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(validation) {
      return validationHelper.createValidationClasses(validation)
    },

    /**
     * Sanitizes the state options to include the `selected` and `disabled` keys.
     * 
     * In the event of default countries, the condition will be set to `''`.
     * 
     * @param {Array}
     */
    sanitizeStateOptions(options) {
      let sanitizedOptions = []
      let optionMap = {
        selected: false,
        disabled: false,
        condition: null
      }

      options.forEach(option => {
        for (let [key, value] of Object.entries(optionMap)) {
          if (! Object.prototype.hasOwnProperty.call(option, key)) {
            option[key] = value
          }
        }

        sanitizedOptions.push(option)
      })

      return sanitizedOptions
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>