<template>
  <label :for="forId" class="fr-label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'FrLabel',

  props: {
    /**
     * The ID of the element the label is for.
     */
    forId: {
      type: String,
      required: false
    },

    /**
     * The HTML to be rendered in place of the label.
     */
    html: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-label {
  display: block;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }
}
</style>