<template>
  <div 
    v-if="step === currentFormStep"
    class="fr-form-step"
  >
    <fr-form-field
      v-for="(field, index) in visibleFields" 
      :key="`${index}-${field.component}`"
      :field="field"
    ></fr-form-field>
  </div>
</template>

<script>
import FrFormField from '@/components/FrFormField.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FrFormStep',

  components: {
    FrFormField
  },

  props: {
    /**
     * The form fields that are being rendered.
     */
    fields: {
      type: Array,
      required: true
    },

    /**
     * The current step of the form being rendered.
     */
    step: {
      type: Number,
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      currentFormStep: 'getCurrentFormStep',
      hiddenFields: 'getHiddenFields',
    }),

    /**
     * The fields that should be rendered.
     * 
     * @returns {Array}
     */
    visibleFields() {
      return this.fields.filter(field => {
        return ! this.hiddenFields.includes(field.component)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-form-step {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>