<template>
    <div class="fr-privacy-text">
        <fr-label>
            <span v-html="privacyHtml"></span>
        </fr-label>
    </div>
</template>
<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import defaultPrivacyText from '@/data/defaults/privacyText.js'
import { mapGetters } from 'vuex'
export default {
  name: 'FrPrivacyText',

  components: {
    FrLabel,
  },

  computed: {
    /** 
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formConfig: 'getFormConfig'
    }),

    /**
     * The SMS consent html to render on the form.
     * 
     * @returns {String}
     */
    privacyHtml() {
      let privacy = this.formConfig.privacy || defaultPrivacyText //load the privacy text object
      let privacyLabel = this.formConfig.privacy.label || defaultPrivacyText.label

      privacyLabel = privacyLabel.replace(/\[LINK\]/g, `<a target="_blank" href="${privacy.url}">${privacy.text}</a>`)

      return privacyLabel
    },

  }, 

}
</script>

<style lang="scss" scoped>
.fr-privacy-text {
  display: flex;
  font-size: 90%;
  margin-top: 0.5em;
}
</style>