<template>
  <option 
    :value="value" 
    :selected="selected" 
    :disabled="disabled"
  >
    <slot></slot>
  </option>
</template>

<script>
export default {
  name: 'FrSelectOption',

  props: {
    /**
     * The value of the select option.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    },

    /**
     * The selected property to be passed to the option.
     */
    selected: {
      type: [String, Number, Boolean],
      required: false,
      default: false
    },
    
    /**
     * The disabled property to be passed to the option.
     */
    disabled: {
      type: [String, Number, Boolean],
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>