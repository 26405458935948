<template>
  <optgroup :label="label">
    <slot></slot>
  </optgroup>
</template>

<script>
export default {
  name: 'FrSelectOptGroup',

  props: {
    /**
     * The optgroup label.
     */
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>