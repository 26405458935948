import axios from 'axios'
import helpers from '@/helpers/helpers'
import validation from '@/validation'

export default {
  /**
   * Initializes the Freya Form Renderer plugin. 
   * 
   * @param {Object} context
   * @param {Object} context.getters
   * @param {Object} context.dispatch
   * @param {Object} payload
   * @param {Object} payload.formConfig
   * 
   * @returns {Void}
   */
  initializePlugin: ({ getters, dispatch }, payload) => {
    if (! ('formConfig' in payload)) {
      console.error('Freya Form Renderer: A form configuration was not found. Please specify a form configuration to properly initialize the plugin.')
    } else {
      dispatch('setFormConfig', payload.formConfig)

      dispatch('updateSubmitObject', {
        key: 'formId',
        value: payload.formConfig.id
      })

      dispatch('setAnalytics')

    }

    /**
     * Set the partner name, if applicable.
     */
    if ('partnerName' in payload) {
      dispatch('setPartnerName', payload.partnerName)
    }

    /**
     * Set the account that the form is associated with, if applicable.
     */
    if ('accountId' in payload) {
      dispatch('updateSubmitObject', {
        key: 'accountId',
        value: payload.accountId
      })
    }

    /**
     * Set whether or not the page is a landing page, if applicable.
     */
    if ('isLp' in payload) {
      dispatch('updateSubmitObject', {
        key: 'isLp',
        value: payload.isLp ?? false
      })
    }

    /**
     * Set whether or not the page is an affiliate page, if applicable.
     */
    if ('isAffiliate' in payload) {
      dispatch('updateSubmitObject', {
        key: 'affiliate',
        value: payload.isAffiliate ?? false
      })
    }

    /**
     * Specify whether or not the form should be showcased as a single program form.
     * 
     * In order to do this, we need to make sure that we have a default program id
     * alongside the single program toggle. In the event we do not have a default
     * program id, we do NOT want to hide the programs field. In this scenario we
     * would want to showcase the programs field as a fallback for stability. 
     */
    if ('isSingleProgram' in payload && 'defaultProgramId' in payload) {
      if (payload.isSingleProgram && payload.defaultProgramId) {
        dispatch('setHiddenFields', [
          'fr-programs'
        ])
      }
    }

    /**
     * Set the default program id on the submit object
     */
    if ('defaultProgramId' in payload && payload.defaultProgramId) {
      dispatch('updateSubmitObject', {
        key: 'program',
        value: payload.defaultProgramId
      })

      if ('isAffiliate' in payload && payload.isAffiliate) {
        let program = getters.getSelectedProgram

        dispatch('updateSubmitObject', {
          key: 'leadBuyerID',
          value: program.account.data.lead_buyer_id ?? null
        })
      }
    }

    /**
     * Sets the submission URL for the form, if applicable.
     */
    if ('submissionUrl' in payload) {
      dispatch('setSubmissionUrl', payload.submissionUrl)
    }

    /**
     * Set the form source.
     */
    dispatch('updateSubmitObject', {
      key: 'formSource',
      value: window.location.href
    })

    /**
     * Set the order ID.
     */
    dispatch('updateSubmitObject', {
      key: 'orderId',
      value: helpers.generateOrderId()
    })

    /**
     * Set the referrer.
     * 
     * We want to make sure to clear the referrer if coming from the same site.
     */
    let referrer = helpers.getCookie('rfi_referrer') || helpers.setCookie('rfi_referrer', document.referrer)
    dispatch('updateSubmitObject', {
      key: 'referrer',
      value: referrer.startsWith(`${location.protocol}//${location.host}`) ? '' : referrer
    })
  },

    /**
   * Sets the analytics values for tracking purposes.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  setAnalytics: ({ commit }) => {
    if (window.gaData) {
      let uaId = Object.keys(window.gaData)[0]

      commit('updateSubmitObject', {
        key: 'gaUaId',
        value: uaId
      })

      commit('updateSubmitObject', {
        key: 'gaClientId',
        value: helpers.getClientId(uaId)
      })
    }
    
    if (window.clientIdIndex) {
      commit('updateSubmitObject', {
        key: 'clientIdIndex',
        value: window.clientIdIndex
      })
    }
  },

  /**
   * Sets the initial values for the form fields.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  setDefaultFieldValues: ({ getters, commit }) => {
    let formSteps = getters.getFormSteps

    formSteps.forEach(step => {
      step.forEach(fieldObject => {
        if ('submitKey' in fieldObject.meta && fieldObject.meta.submitKey) {
          commit('updateSubmitObject', {
            key: fieldObject.meta.submitKey,
            value: fieldObject.meta.default ?? ''
          })
        }
      })
    })
  },

  /**
   * Sets the initial values for the query string.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  setDefaultQueryStringValues: ({ commit }) => {
    let queryString = helpers.getCookie('rfi_query_string') || helpers.setCookie(
      'rfi_query_string',
      window.location.search.substring(1).replace(/&&/, '&'),
      1
    )

    let queryParameters = helpers.parseQueryParameters(queryString)

    for (let key in queryParameters) {
      commit('updateSubmitObject', {
        key: key,
        value: queryParameters[key]
      })
    }
  },

  /**
   * Initialize all of the validation rules present on the form across all fields.
   * 
   * @param {Object} context 
   * 
   * @returns {Void}
   */
  initializeValidationRules: ({ getters }) => {
    let fields = getters.getFormFields

    let rules = fields.filter(field => {
      // Remove the fields that do not have rules defined.
      if ('rules' in field.meta) {
        return field
      }
    }).map(field => {
      // Map these rules to an array containing the rule names so that they may be imported.
      return field.meta.rules.map(rule => {
        return rule.name
      })
    }).flat()

    validation.init([...new Set(rules)])
  },

  /**
   * Sets the partner's name.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setPartnerName: ({ commit }, payload) => {
    commit('setPartnerName', payload)
  },
    
  /**
   * Sets the form config to the given value.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {Object} context.dispatch
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  setFormConfig: ({ commit, dispatch }, payload) => {
    commit('setFormConfig', payload)

    dispatch('setDefaultFieldValues')
    dispatch('setDefaultQueryStringValues')
    dispatch('initializeValidationRules')
  },

  /**
   * Increments the current form step.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  incrementFormStep: ({ getters, commit }) => {
    commit(
      'setCurrentFormStep', 
      getters.getCurrentFormStep + 1
    )
  },

  /**
   * Decrements the current form step.
   * 
   * @param {Object} context 
   * @param {Object} context.getters
   * @param {Object} context.commit
   * 
   * @returns {Void}
   */
  decrementFormStep: ({ getters, commit }) => {
    commit(
      'setCurrentFormStep', 
      getters.getCurrentFormStep - 1
    )
  },

  /**
   * Updates the submit object.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  updateSubmitObject: ({ commit }, payload) => {
    commit('updateSubmitObject', payload)
  },

  /**
   * Sets the submission URL for the form.
   * 
   * @param {Object} context
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSubmissionUrl: ({ commit }, payload) => {
    commit('setSubmissionUrl', payload)
  },

  /**
   * Sets the programs filter.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setProgramsFilter: ({ commit }, payload) => {
    commit('setProgramsFilter', payload)
  },

  /**
   * Sets the fields that should not be rendered.
   * 
   * @param {Object} context 
   * @param {Object} context.commit
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setHiddenFields: ({ commit }, payload) => {
    commit('setHiddenFields', payload)
  },

  /**
   * Submits the form to the submission URL specified.
   * 
   * @param {Object} context
   * @param {Object} context.getters
   * 
   * @returns {Void}
   */
  submitForm: ({ getters }) => {
    let submissionData = {
      ...getters.getSubmitObject,
      programCode: getters.getSelectedProgram.program_code ?? null
    }

    // Check the program qualifier to see if we need a substitution.
    // We only want to substitute the program if the qualifier 'fails'.
    let qualifier = getters.getSelectedProgramQualifier
    let redirectUrl = getters.getRedirectUrl

    if (
      'substitution' in qualifier && 
      qualifier.substitution && 
      submissionData.programQualifier === false
    ) {
      submissionData.program = qualifier.program_uuid
      redirectUrl = qualifier.redirect_url
    }
    /**
     * Set the state if zip is in the submission obj. and not country/state
     */
    if('zip' in submissionData) {
      let state = helpers.getState()
      if(state) {
        submissionData.state = state
      }
      
    }

    axios.post(
      getters.getSubmissionUrl, 
      submissionData
    ).then(() => {
      helpers.redirectAfterSubmission(
        redirectUrl, 
        submissionData
      )
    }).catch(err => {
      console.error(err)
    })
  }
}