<template>
  <ValidationObserver 
    v-slot="validation" 
    slim
  >
    <div class="fr-form">
      <h2 
        v-if="form.heading" 
        class="fr-form__heading">
        {{ form.heading }}
      </h2>
      <p 
        v-if="form.intro"
        class="fr-form__intro">
        {{ form.intro }}
      </p>

      <template v-for="(step, index) in formSteps">
        <fr-form-step 
          :key="`step-${index + 1}`"
          :step="index + 1" 
          :fields="step" 
          @keydown.native.enter.prevent="handleEnterKey(validation)"
        ></fr-form-step>
      </template>
      <fr-form-controls :validation="validation"></fr-form-controls>
      <fr-privacy-text></fr-privacy-text>
    </div>
  </ValidationObserver>
</template>

<script>
import FrFormControls from '@/components/FrFormControls.vue'
import FrFormStep from '@/components/FrFormStep.vue'
import FrPrivacyText from '@/components/FrPrivacyText.vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FormRenderer',
  components: {
    FrFormControls,
    FrFormStep,
    FrPrivacyText,
    ValidationObserver,
  },

  data: () => ({
    //
  }),

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      form: 'getFormConfig',
      formSteps: 'getFormSteps',
      currentFormStep: 'getCurrentFormStep',
      formStepsCount: 'getFormStepsCount'
    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
    ...mapActions([
      'incrementFormStep',
      'submitForm'
    ]),

    /**
     * Handles what happens when the enter key is pressed within the form.
     * 
     * This will either increment the form step, or attempt to submit the form.
     * 
     * @returns {Void}
     */
    handleEnterKey(validation) {
      if (this.currentFormStep < this.formStepsCount) {
        validation.handleSubmit(this.incrementFormStep)
      } else {
        validation.handleSubmit(this.submitForm)
      }
    }
  },

  mounted() {
    //
  },

  created() {
    //
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
</style>
