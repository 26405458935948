<template>
  <span class="fr-field-error">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'FrFieldError'
}
</script>

<style lang="scss" scoped>
.fr-field-error {
  color: red;
}
</style>