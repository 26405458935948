<template>
  <validation-provider 
    v-if="qualifier"
    :rules="rulesObject"
    :custom-messages="messagesObject" 
    v-slot="validation"
    slim
  >
    <div :class="{...cssClasses, ...validationClasses(validation)}">
      <fr-label 
        for-id="fr-program-qualifier-dropdown"
        :class="validationClasses(validation)"
      >
        {{ qualifier.label }}
      </fr-label>
      <fr-select
        id="fr-program-qualifier-dropdown"
        :class="validationClasses(validation)"
        name="fr-program-qualifier-dropdown"
        v-model="fieldValue"
        :options="programQualifierOptions"
      ></fr-select>
      <fr-field-error v-if="validation.errors.length > 0">{{ validation.errors[0] }}</fr-field-error>
    </div>
  </validation-provider>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import FrFieldError from '@/components/fields/base/FrFieldError.vue'
import validationHelper from '@/helpers/validation'
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'FrProgramQualifierDropdown',

  components: {
    FrLabel,
    FrSelect,
    FrFieldError,
    ValidationProvider
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programs: 'getPrograms',
      selectedProgramId: 'getSelectedProgramId'
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-program-qualifier-dropdown': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },

    /**
     * Creates a rules object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    rulesObject() {
      let rules = validationHelper.createRulesObject(this.field.meta.rules)

      // Remove the 'required' rule if we want to handle program substitution.
      if ('substitution' in this.qualifier && this.qualifier.substitution) {
        delete rules.required
      }

      return rules
    },

    /**
     * Creates a messages object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    messagesObject() {
      return validationHelper.createMessagesObject(this.field.meta.rules)
    },

    /**
     * The default options to be rendered by the qualifier.
     * 
     * @returns {Array}
     */
    defaultOptions() {
      return [
        {
          label: 'Yes',
          value: true,
          selected: false,
          disabled: false
        },
        {
          label: 'No',
          value: false,
          selected: false,
          disabled: false
        }
      ]
    },

    /**
     * The program qualifier options to display on the form.
     * 
     * @returns {Array}
     */
    programQualifierOptions() {
      if (
        this.qualifier && 
        Object.prototype.hasOwnProperty.call(this.qualifier, 'options') && 
        this.qualifier.options.length > 0
      ) {
        return this.qualifier.options
      } else {
        return this.defaultOptions
      }
    },

    /**
     * The program object that is currently selected.
     * 
     * TODO: Potentially extract this to a getter on the store.
     * This would abstract the data and logic away from this component
     * in case the functionality is needed again elsewhere in the codebase.
     * 
     * @returns {Object}
     */
    selectedProgram() {
      return this.programs.find(program => program.id === this.selectedProgramId)
    },

    /**
     * The qualifier object for the currently selected program.
     * 
     * @returns {Object|null}
     */
    qualifier() {
      // All of these checks need to be in place for sanity.
      // I'll come back and refactor this at some point to make it prettier.
      if (
        ! this.selectedProgram || // There is no selected program.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram, 'programQualifier') || // The selected program doesn't have the qualifier key.
        ! Object.prototype.hasOwnProperty.call(this.selectedProgram.programQualifier, 'data') || // The selected program doesn't have the data key.
        Object.keys(this.selectedProgram.programQualifier.data).length === 0 || // The selected program doesn't have a qualifier.
        this.selectedProgram.programQualifier.data.type !== 'dropdown' // The selected program isn't of the 'dropdown' type.
      ) {
        return null
      }

      return this.selectedProgram.programQualifier.data
    }
  },

  methods: {
    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(validation) {
      return validationHelper.createValidationClasses(validation)
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>