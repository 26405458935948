export default {
  /**
   * The Form Config object.
   * 
   * This is where all of the data about the form lives.
   * 
   * This should not be modified.
   * 
   * @param {Object}
   */
  formConfig: {},

  /**
   * The Submit object.
   * 
   * This is where all of the RFI submission data is stored.
   * 
   * @param {Object}
   */
  submit: {},

  /**
   * The submission URL for the form.
   * 
   * @param {String}
   */
  submissionUrl: 'https://rfi.edu.help/v1/leads/sites',

  /**
   * Computed metadata about the form config steps.
   * 
   * @param {Object}
   */
  steps: {
    /**
     * The current step the form is on.
     * 
     * @param {Number}
     */
    current: 1
  },

  /**
   * The partner's name.
   * 
   * @param {String}
   */
  partnerName: 'PLEASE SET THE PARTNER NAME',

  /**
   * The data to use when filter programs.
   * 
   * @param {Object}
   */
  programsFilter: {
    /**
     * The label to use for the select option.
     * 
     * @param {String}
     */
    label: '',

    /**
     * The field by which to match the value to.
     * 
     * @param {String}
     */
    field: '',

    /**
     * The value to match against.
     * 
     * @param {String}
     */
    value: ''
  },

  /**
   * The fields that should not be rendered.
   * 
   * @param {Array}
   */
  hiddenFields: []
}