<template>
  <validation-provider
    :rules="rulesObject"
    :custom-messages="messagesObject" 
    v-slot="validation"
    slim
  >
    <div :class="{...cssClasses, ...validationClasses(validation)}">
      <fr-label 
        for-id="fr-country"
        :class="validationClasses(validation)"
      >
        {{ field.meta.label }}
      </fr-label>
      <fr-select
        id="fr-country"
        :class="validationClasses(validation)"
        name="fr-country"
        v-model="fieldValue"
        :options="countryOptions"
      ></fr-select>
      <fr-field-error v-if="validation.errors.length > 0">{{ validation.errors[0] }}</fr-field-error>
    </div>
  </validation-provider>
</template>

<script>
import defaultCountries from '@/data/defaults/countries.js'
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import FrFieldError from '@/components/fields/base/FrFieldError.vue'
import validationHelper from '@/helpers/validation'
import { ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'

export default {
  name: 'FrCountry',

  components: {
    FrLabel,
    FrSelect,
    FrFieldError,
    ValidationProvider
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Object}
     */
    cssClasses() {
      return {
        'fr-country': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },

    /**
     * Creates a rules object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    rulesObject() {
      return validationHelper.createRulesObject(this.field.meta.rules)
    },

    /**
     * Creates a messages object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    messagesObject() {
      return validationHelper.createMessagesObject(this.field.meta.rules)
    },
    
    /**
     * The default options to be rendered by the programs filter.
     * 
     * @returns {Array}
     */
    defaultOptions() {
      return [{
        label: this.field.meta.placeholder ?? 'Please select a Country',
        value: '',
        selected: true,
        disabled: true
      }]
    },

    /**
     * The countries to display on the form.
     * 
     * Countries may be overridden to have values either replaced, or appended.
     * 
     * @returns {Array}
     */
    countryOptions() {
      let countries = []

      if (this.field.meta.optionsMode === 'replace' && this.field.meta.options.length > 0) {
        countries.push(...this.field.meta.options)
      } else {
        countries.push(...defaultCountries, ...this.field.meta.options)
      }

      countries = this.sanitizeCountryOptions(countries)

      return [
        ...this.defaultOptions,
        ...countries.sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by label.
      ]
    }
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      updateSubmitObject: 'updateSubmitObject'
    }),

    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(validation) {
      return validationHelper.createValidationClasses(validation)
    },

    /**
     * Sanitizes the country options to include the `selected` and `disabled` keys.
     * 
     * @param {Array}
     */
    sanitizeCountryOptions(countries) {
      let sanitizedOptions = []
      let optionMap = {
        selected: false,
        disabled: false
      }

      countries.forEach(option => {
        for (let [key, value] of Object.entries(optionMap)) {
          if (! Object.prototype.hasOwnProperty.call(option, key)) {
            option[key] = value
          }
        }

        sanitizedOptions.push(option)
      })

      return sanitizedOptions
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>