import { extend } from 'vee-validate'
import * as baseRules from 'vee-validate/dist/rules'
import * as customRules from '@/validation/rules'

export default {
  init(rules) {
    let availableRules = {
      ...baseRules,
      ...customRules
    }

    rules.forEach(rule => {
      if (Object.prototype.hasOwnProperty.call(availableRules, rule)) {
        extend(rule, availableRules[rule])
      } else {
        console.warn(`Freya Form Renderer: A validation rule for ${rule} was not found.`)
      }
    })
  }
}