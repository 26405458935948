import Vue from 'vue'

export default {
  /**
   * Sets the partner's name.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setPartnerName: (state, payload) => {
    state.partnerName = payload
  },
  
  /**
   * Sets the form config to the given value.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setFormConfig: (state, payload) => {
    state.formConfig = payload
  },

  /**
   * Sets the submission URL for the form.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSubmissionUrl: (state, payload) => {
    state.submissionUrl = payload
  },

  /**
   * Sets the current step of the form.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setCurrentFormStep: (state, payload) => {
    state.steps.current = payload
  },

  /**
   * Updates the submit object.
   * 
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  updateSubmitObject: (state, payload) => {
    // We need to check if the key exists on the state first. If it does not exist,
    // then we need to explicitly set it using Vue so we can track reactivity.
    if (! Object.prototype.hasOwnProperty.call(state, payload.key)) {
      Vue.set(state.submit, payload.key, payload.value)
    } else {
      state.submit[payload.key] = payload.value
    }
  },

  /**
   * Sets the programs filter.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setProgramsFilter: (state, payload) => {
    state.programsFilter = payload
  },

  /**
   * Sets the fields that should not be rendered.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setHiddenFields: (state, payload) => {
    state.hiddenFields = payload
  }
}