import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import helpers from './helpers/helpers'
import FormRendererStore from './store'
import FormRenderer from './FormRenderer.vue'

window.FreyaFormManager = class FreyaFormManager {
  /**
   * Creates a new instance of FreyaFormManager.
   * 
   * This will bootstrap Freya Form Renderer and all of the necessary components.
   * 
   * @param {Object}  [config]       The root configuration object
   * @param {Boolean} [config.debug] Whether or not the developer tools should be enabled
   * 
   * @returns {Void}
   * 
   * @memberof FreyaFormManager
   */
  constructor(config = {}) {
    let queryParameters = helpers.parseQueryParameters(window.location.search.substring(1).replace(/&&/, '&'))

    Vue.config.devtools = config.debug ?? queryParameters.debug ?? false
    Vue.use(Vuex)

    // We need to expose all of the field components globally so that they can be referenced dynamically.
    let FormFieldComponents = require.context('./components/fields', true, /.vue$/)

    // Register each of the field components globally.
    FormFieldComponents.keys().forEach(filename => {
      let module = FormFieldComponents(filename)
      let moduleName = filename.replace(/(^.*[\\/])|(.vue)/ig, "") // Strip out all but the filename.
        .replace(/([a-z])([A-Z])/g, "$1-$2") // Convert to kebab-case
        .toLowerCase()

      Vue.component(moduleName, module.default || module)
    })
  }

  /**
   * Fetches the JSON configuration from the Sites API.
   * 
   * @param {Object} config           The root configuration object
   * @param {String} config.formId    The id of the form that should be rendered
   * @param {String} [config.baseUrl] The base url that will be used to fetch the form configuration
   * 
   * @returns {Promise}
   * 
   * @memberof FreyaFormManager
   */
  async _fetchFormConfig(config) {
    if (! ('formId' in config)) {
      throw new Error('Freya Form Renderer: No form id was provided. Please specify a form id to continue.')
    }

    let productionUrl = 'https://content.edu.help/v1/form'
    let baseUrl = config.baseUrl || productionUrl
    
    return await axios.get(baseUrl.replace(/\/+$/, '') + `/${config.formId}`)
  }

  /**
   * Create a new instance of the form and bind it to the specified element.
   * 
   * Minimum required fields on the config object are;
   * 
   * `formId`
   * `partnerName` 
   * `accountId`
   * 
   * @param {Object}  config                    The root configuration object
   * @param {String}  config.formId             The id of the form that should be rendered
   * @param {String}  config.partnerName        The desired display name for the partner
   * @param {String}  config.accountId          The uuid for the associated partner's account
   * @param {Boolean} [config.isLp]             Whether or not the form is for a landing page
   * @param {Boolean} [config.isSingleProgram]  Whether or not the form should be a single program variant and should hide the programs field
   * @param {String}  [config.defaultProgramId] The default program to be used when the form is rendered
   * @param {String}  [config.element]          The element that the form should be bound to. Must include the element descriptor (#, .)
   * @param {String}  [config.baseUrl]          The base url that will be used to fetch the form configuration
   * 
   * @returns {Promise}
   * 
   * @memberof FreyaFormManager
   */
  async create(config) {
    if (! ('formId' in config)) {
      throw new Error('Freya Form Renderer: No form id was provided. Please specify a form id to continue.')
    }

    let formConfig

    try {
      // Extract the `data` parameter from the response and assign it to the `formConfig` variable defined above.
      ({ data: formConfig } = await this._fetchFormConfig({
        formId: config.formId,
        baseUrl: config.baseUrl || ''
      }))
    } catch (errors) {
      throw new Error('Freya Form Renderer: There was a problem retrieving the specified form.')
    }

    new Vue({
      render: h => h(FormRenderer),
      store: new Vuex.Store(FormRendererStore),
      created() {
        this.$store.dispatch('initializePlugin', {
          ...config,
          formConfig
        })
      }
    }).$mount(config.element || '#wes-form')
  }
}