<template>
  <validation-provider
    :rules="rulesObject"
    :custom-messages="messagesObject"
    v-slot="validation"
    mode="eager"
    slim
  >
    <div :class="{...cssClasses, ...validationClasses(validation)}">
      <fr-label 
        for-id="fr-programs"
        :class="validationClasses(validation)"
      >
        {{ field.meta.label }}
      </fr-label>
      <fr-select
        id="fr-programs"
        :class="validationClasses(validation)"
        name="fr-programs"
        v-model="fieldValue"
        :default-option="defaultOption"
        :options="programOptions"
      ></fr-select>
      <fr-field-error v-if="validation.errors.length > 0">{{ validation.errors[0] }}</fr-field-error>
    </div>
  </validation-provider>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import FrFieldError from '@/components/fields/base/FrFieldError.vue'
import validationHelper from '@/helpers/validation'
import { ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FrPrograms',

  components: {
    FrLabel,
    FrSelect,
    FrFieldError,
    ValidationProvider
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programs: 'getPrograms',
      submitObject: 'getSubmitObject',
      programsFilter: 'getProgramsFilter'
    }),

    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-programs': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },

    /**
     * Creates a rules object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    rulesObject() {
      return validationHelper.createRulesObject(this.field.meta.rules)
    },

    /**
     * Creates a messages object to pass to the validation provider.
     * 
     * @returns {Object}
     */
    messagesObject() {
      return validationHelper.createMessagesObject(this.field.meta.rules)
    },

    /**
     * The default option to be rendered by the programs dropdown.
     * 
     * @returns {Object}
     */
    defaultOption() {
      return {
        label: this.field.meta.placeholder ?? 'Please select a Program',
        value: '',
        selected: true,
        disabled: true
      }
    },

    /**
     * The programs to display on the form.
     * 
     * This will also filter the programs based on various criteria.
     * 
     * @returns {Array}
     */
    programOptions() {
      let programs = []
      let rawPrograms = this.programs
      let filter = this.programsFilter
      let groups = this.field.meta.groups

      /**
       * Filter all of the programs down to only the ones that match the filter.
       */
      if (Object.prototype.hasOwnProperty.call(filter, 'value') && filter.value) {
        // We need to look a level deeper and sort specifically for groups.
        if (filter.field === 'group') {
          rawPrograms = rawPrograms.filter(program => {
            return program.groups.data.find(group => group.id === filter.value)
          })
        } else {
          rawPrograms = rawPrograms.filter(program => {
            return program[filter.field] === filter.value
          })
        }
      }

      /** 
       * This is a bit ugly, but this will compile an array of options grouped by the specified criteria.
       * 
       * If there is no criteria to group by, we simply return a flat array of programs to render.
       */
      if (groups.length > 0) {
        groups.forEach(group => {
          // Find the group's option if it already exists.
          let groupIndex = programs.findIndex(item => {
            return (item.label === group.label) && (Object.prototype.hasOwnProperty.call(item, 'items'))
          })

          // Initialize the group's option so that we may attach the programs to the group.
          if (groupIndex === -1) {
            groupIndex = programs.push({
              label: group.label,
              items: []
            })

            groupIndex--
          }

          /**
           * Program groups need to be checked explicitly since they are nested into a relation object.
           * 
           * After program groups are checked, we can cross reference the value of the program field 
           * indicated on the filter object. If any of these return programs, we want to assign them
           * to the proper option as items so that they ay be rendered under the correct optgroup.
           */
          if (group.field === 'group') {
            programs[groupIndex].items.push(
              ...rawPrograms.filter(program => {
                return program.groups.data.find(item => item.id === group.value)
              }).map(program => {
                return {
                  label: program.display_name ?? program.name,
                  value: program.id,
                  selected: false,
                  disabled: false
                }
              })
            )
          } else {
            programs[groupIndex].items.push(
              ...rawPrograms.filter(program => {
                return program[group.field] === group.value
              }).map(program => {
                return {
                  label: program.display_name ?? program.name,
                  value: program.id,
                  selected: false,
                  disabled: false
                }
              })
            )
          }
        })
      } else {
        programs.push(
          ...rawPrograms.map(program => {
            return {
              label: program.display_name ?? program.name,
              value: program.id,
              selected: false,
              disabled: false
            }
          })
        )
      }

      return [
        ...programs
      ]
    }
  },

  watch: {
    /**
     * Reset the program field if the filter is updated.
     */
    programsFilter() {
      this.fieldValue = ''
    },

    /**
     * Update the leadBuyerID if the page is marked as an affiliate.
     */
    fieldValue(newVal) {
      if ('affiliate' in this.submitObject && this.submitObject.affiliate) {
        let program = this.programs.find(prog => prog.id === newVal)

        this.updateSubmitObject({
          key: 'leadBuyerID',
          value: program.account.data.lead_buyer_id ?? null
        })
      }
    }
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      updateSubmitObject: 'updateSubmitObject'
    }),

    /** 
     * The CSS classes to be applied for validation purposes.
     * 
     * @returns {Object}
     */
    validationClasses(validation) {
      return validationHelper.createValidationClasses(validation)
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>