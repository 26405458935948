export default {
  /**
   * The partner's name.
   * 
   * @param {Object} state
   * 
   * @returns {String}
   */
  getPartnerName: state => {
    return state.partnerName
  },

  /**
   * The form config stored in the state.
   * 
   * @param {Object} state 
   * 
   * @returns {Object}
   */
  getFormConfig: state => {
    return state.formConfig
  },

  /**
   * The steps present on the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * 
   * @returns {Array}
   */
  getFormSteps: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'steps')) {
      return []
    }

    return formConfig.steps
  },

  /**
   * The number of steps associated with the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * 
   * @returns {Number}
   */
  getFormStepsCount: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'steps')) {
      return 0  
    }

    return formConfig.steps.length
  },

  /**
   * The current step of the form.
   * 
   * @param {Object} state
   * 
   * @returns {Number}
   */
  getCurrentFormStep: state => {
    return state.steps.current
  },

  /**
   * The list of form fields associated with the form config.
   * 
   * Note: This will return the full list of fields across all steps.
   * 
   * @param {Object} state 
   * @param {Object} getters
   * 
   * @returns {Array}
   */
  getFormFields: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'steps')) {
      return []
    }

    let fields = []

    formConfig.steps.forEach(stepFields => {
      fields.push(...stepFields)
    })

    return fields
  },

  /**
   * The programs associated with the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters
   * 
   * @returns {Array}
   */
  getPrograms: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'programs')) {
      return []
    }

    return formConfig.programs
  },

  /** 
   * The submit object to be sent with the RFI.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getSubmitObject: state => {
    return state.submit
  },

  /**
   * The submission URL for the form.
   * 
   * @param {Object} state
   * 
   * @returns {String}
   */
  getSubmissionUrl: state => {
    return state.submissionUrl
  },

  /**
   * The redirect URL for the form.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getRedirectUrl: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'redirect')) {
      return ''
    }

    return formConfig.redirect
  },

  /** 
   * The currently selected country.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getSelectedCountry: (state, getters) => {
    let submitObject = getters.getSubmitObject

    if (! Object.prototype.hasOwnProperty.call(submitObject, 'country')) {
      return ''
    }

    return submitObject.country
  },

  /**
   * The currently selected program ID.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getSelectedProgramId: (state, getters) => {
    let submitObject = getters.getSubmitObject

    if (! Object.prototype.hasOwnProperty.call(submitObject, 'program')) {
      return ''
    }

    return submitObject.program
  },

  /**
   * The currently selected program.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {Object}
   */
  getSelectedProgram: (state, getters) => {
    let programId = getters.getSelectedProgramId
    let programs = getters.getPrograms

    let program = programs.filter(program => program.id === programId)[0] ?? {}

    return program
  },

  /**
   * The selected program's qualifier.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {Object}
   */
  getSelectedProgramQualifier: (state, getters) => {
    let program = getters.getSelectedProgram
    let qualifier

    try {
      qualifier = program.programQualifier.data
    } catch {
      qualifier = {}
    }

    return qualifier
  },

  /** 
   * The currently selected program filter.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getProgramsFilter: state => {
    return state.programsFilter
  },

  /** 
   * The fields that should not be rendered.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getHiddenFields: state => {
    return state.hiddenFields
  }
}