<template>
  <input 
    :id="id"
    :class="cssClasses"
    :name="name"
    :type="type"
    :placeholder="placeholder"
    :pattern="pattern"
    :autocomplete="autocomplete"
    :disabled="disabled"
    v-model="fieldValue"
  />
</template>

<script>
export default {
  name: 'FrInput',

  props: {
    /**
     * The ID of the input element.
     */
    id: {
      type: String,
      required: true
    },

    /** 
     * The name of the input element.
     */
    name: {
      type: String,
      required: false
    },

    /** 
     * The type of the input element.
     */
    type: {
      type: String,
      required: false
    },

    /** 
     * The placeholder for the input element.
     */
    placeholder: {
      type: [String, Number],
      required: false
    },

    /** 
     * The pattern for the input element.
     */
    pattern: {
      type: String,
      required: false
    },

    /** 
     * The autocomplete property to be passed to the input element.
     */
    autocomplete: {
      type: [String, Number, Boolean],
      required: false
    },

    /** 
     * The disabled property to be passed to the input element.
     */
    disabled: {
      type: [String, Number, Boolean],
      required: false
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return [
        'fr-input'
      ]
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {String} val
     * 
     * @returns {String}
     */
    fieldValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    }
  }
}
</script>

<style lang="scss" scoped>
.fr-input:not([type="checkbox"]) {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}

.fr-input[type="checkbox"] {
  margin-right: 10px;
}
</style>