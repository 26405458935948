export default {
  /**
   * Creates a rules object to pass to the validation provider.
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */
  createRulesObject(rules) {
    let ruleObject = {}

    rules.forEach(rule => {
      ruleObject[rule.name] = rule.parameters ?? true
    })

    return ruleObject
  },

  /**
   * Creates a messages object to pass to the validation provider.
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */
  createMessagesObject(rules) {
    let messageObject = {}

    rules.forEach(rule => {
      messageObject[rule.name] = rule.message
    })

    return messageObject
  },

  /** 
   * The CSS classes to be applied for validation purposes.
   * 
   * @returns {Object}
   */
  createValidationClasses(validation) {
    return {
      'validated': validation.validated,
      'valid': validation.validated && validation.valid,
      'invalid': validation.validated && validation.invalid
    }
  }
}