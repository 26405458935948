export default {
  /**
   * Sets a cookie on the browser instance for the specified amount of days.
   * 
   * Cookie format: {key}={value}; {expires}={date}; {path}={/}
   * 
   * @param {String} key
   * @param {String} value
   * @param {Number} ttl
   * 
   * @returns {String}
   */
  setCookie(key, value, ttl = 1) {
    let expires = ''
    let path = '; path=/'

    if (ttl) {
      let date = new Date()
      date.setTime(date.getTime() + (ttl * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toGMTString()}`
    }

    document.cookie = `${key}=${value}${expires}${path}`

    return value
  },

  /**
   * Retrieve a cookie with the specified name.
   * 
   * @param {String} key
   * 
   * @returns {String|Null}
   */
  getCookie(name = '') {
    let cookieArray = document.cookie.split(`;`);
    
    for(let i = 0; i < cookieArray.length; i++) {
      if (cookieArray[i].trim().startsWith(`${name}=`)) {
        let cookie

        try {
          cookie = cookieArray[i].split(`${name}=`)[1]
        } catch (e) {
          console.warn(`Freya Form Renderer: There was a problem retrieving the cookie '${name}'.`)

          return null
        }

        return cookie
      }
    }

    return null;
  },

  /**
   * Extract the query string parameters into an object.
   * 
   * @param {String} queryString
   * 
   * @return {Object}
   */
  parseQueryParameters(queryString) {
    let lookup = {}
    let parameterArray = queryString.split('&')

    parameterArray.forEach(pair => {
      let pairArray = pair.split(/=(.+)/)
      
      if (pairArray.length > 1) {
        lookup[pairArray[0]] = decodeURIComponent(
          pairArray[1].replace(/\+/g, ' ')
        )
      }
    })

    return lookup
  },

  /**
   * Redirects the user after the form has been submitted.
   * 
   * TODO: This is kind of sloppy, but it works for now.
   * At some point it would be good to find a better way to handle this.
   * 
   * @param {String}  redirect
   * @param {Object}  data
   * 
   * @return {Void}
   */
  redirectAfterSubmission(redirect, data) {
    let queryString = []

    if (Object.prototype.hasOwnProperty.call(data, 'isLp')) {
      queryString.push(`lp=${data.isLp}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'orderId')) {
      queryString.push(`orderid=${data.orderId}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'program')) {
      queryString.push(`uuid=${data.program}`)
    }

    if (Object.prototype.hasOwnProperty.call(data, 'programCode')) {
      queryString.push(`p_code=${data.programCode}`)
    }

    window.location = `/${redirect}/?${queryString.join('&')}`
  },

  /**
   * Generates an order ID.
   * 
   * @returns {String}
   */
  generateOrderId() {
    return (Date.now() + Math.floor(1000 + Math.random() * 9000)).toString()
  },

   /**
   * Finds the client id associated with analytics.
   * 
   * @returns {String} 
   */
  getClientId(uaid) {
    if (window.ga) {
      try {
        let trackers = window.ga.getAll()

        for (let i = 0, len = trackers.length; i < len; i++) {
          if (trackers[i].get('trackingId') === uaid) {
            return trackers[i].get('clientId')
          }
        }
      } catch (error) { 
        // Fail silently...
        console.error(error)
      }
    }

    return 'false'
  },  
  /**
   * Gets the US state from the window storage object
   * 
   * @returns {String}
   */
  getState() {
    return window.localStorage.state
  }
}