<template>
  <select
    :id="id"
    :class="cssClasses"
    :name="name"
    v-model="fieldValue"
  >
    <!-- Default Option -->
    <fr-select-option 
      v-if="defaultOption"
      :value="defaultOption.value"
      :selected="defaultOption.selected" 
      :disabled="defaultOption.disabled"
    >
      {{ defaultOption.label }}
    </fr-select-option>

    <template v-for="(option, optionIndex) in options">
      <!-- If the object has a `value` key, it is a single option. -->
      <template v-if="Object.prototype.hasOwnProperty.call(option, 'value')">
        <fr-select-option
          :key="optionIndex"
          :value="option.value"
          :selected="option.selected"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </fr-select-option>
      </template>

      <!-- If the object has no `value` key, and has `items`, it is an optgroup. -->
      <template v-if="Object.prototype.hasOwnProperty.call(option, 'items')">
        <fr-select-opt-group
          v-if="option.items.length > 0"
          :key="optionIndex"
          :label="option.label"
        >
          <fr-select-option
            v-for="(item, itemIndex) in option.items"
            :key="itemIndex"
            :value="item.value"
            :selected="item.selected"
            :disabled="item.disabled"
          >
            {{ item.label }}
          </fr-select-option>
        </fr-select-opt-group>
      </template>
    </template>
  </select>
</template>

<script>
import FrSelectOption from '@/components/fields/base/FrSelectOption.vue'
import FrSelectOptGroup from '@/components/fields/base/FrSelectOptGroup.vue'

export default {
  name: 'FrSelect',

  components: {
    FrSelectOption,
    FrSelectOptGroup
  },

  props: {
    /**
     * The ID of the select element.
     */
    id: {
      type: String,
      required: true
    },

    /** 
     * The name of the select element.
     */
    name: {
      type: String,
      required: false
    },

    /**
     * The default option to render for the select element.
     * 
     * This will always be displayed at the top of the list.
     */
    defaultOption: {
      type: Object,
      required: false
    },

    /**
     * The options to render for the select element.
     */
    options: {
      type: Array,
      required: true
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: true
    }
  },

  computed: {
    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return [
        'fr-select'
      ]
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {String} val
     * 
     * @returns {String}
     */
    fieldValue: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },
  }
}
</script>

<style lang="scss" scoped>
.fr-select {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);
  
  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}
</style>