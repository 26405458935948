import { render, staticRenderFns } from "./FrFormControls.vue?vue&type=template&id=4f9897ce&scoped=true&"
import script from "./FrFormControls.vue?vue&type=script&lang=js&"
export * from "./FrFormControls.vue?vue&type=script&lang=js&"
import style0 from "./FrFormControls.vue?vue&type=style&index=0&id=4f9897ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9897ce",
  null
  
)

export default component.exports